// -------------------------------------
// Страница описания проекта
// -------------------------------------

// Для декодирование специальных символов нужно установить такой патке:
// npm install html-entities

//import React from "react";
import FooterNavigation from '../components/footerNavigation';
import { useTranslation } from 'react-i18next';
import { getDaysDifference, formatDateByLocale } from '../components/utils';
import i18n from '../i18n';
import { decode } from 'html-entities';

import { ReactComponent as LogoIcon } from '../assets/skyrun_inline_cloud.svg';
import CONSTANTS from '../components/constants';

export function AboutPage() {

  // Загружаем переводы
  const {t} = useTranslation();

  const handleClick = () => {
    window.open(CONSTANTS.coreUrl, '_blank');
  }
  
  return (
    <div className="App">

      <div className="container">

        <div className="about-header">
          <div>
            <LogoIcon
                className="image-page-title-about"  onContextMenu={(e) => e.preventDefault()} onDragStart={(e) => e.preventDefault()}>    
            </LogoIcon>
          </div>

        </div>

        <div className="about-description justified">
            
            <div 
              className="about-link centered" onClick={handleClick}>
                {CONSTANTS.coreUrl}
            </div>

            <br />

            <ol>
              <a href="#article_1" className="vlink"><li>{decode(t('about1_1'))}</li></a>
              <a href="#article_2" className="vlink"><li>{decode(t('about2_1'))}</li></a>
              <a href="#article_3" className="vlink"><li>{decode(t('about3_1'))}</li></a>
              <a href="#article_4" className="vlink"><li>{decode(t('about4_1'))}</li></a>
              <a href="#article_5" className="vlink"><li>{decode(t('about5_1'))}</li></a>
            </ol>
            

            <br />

            {/* ------------------------------------------- */}
            <h3 id="article_1">1. {decode(t('about1_1'))}</h3>
            
            <br />

            {decode(t('about1_2'))}.
            
            <br />
            <br />

            {decode(t('about1_3'))}. {decode(t('about1_4'))}. {decode(t('about1_5'))}. {decode(t('about1_6'))}. {decode(t('about1_7'))}. 

            <br />
            <br />
            
            {decode(t('about1_8'))}. {decode(t('about1_9'))}. {decode(t('about1_10'))}. {decode(t('about1_11'))}.

            <br />
            <br />
            <br />

            {/* ------------------------------------------- */}
            <h3 id="article_2">2. {decode(t('about2_1'))}</h3>

            <br />

            {decode(t('about2_2'))}. {decode(t('about2_3'))}. {decode(t('about2_4'))}. {decode(t('about2_5'))}. 
            
            <br />
            <br />

            {decode(t('about2_6'))}

            <br />
            <br />
            
            <ul>
              <li>
                {decode(t('about2_7_0'))};
              </li>
              <li>
                {decode(t('about2_7_1'))} <span className='remark'>1 USD</span> {decode(t('about2_7_2'))}. {decode(t('about2_8'))};
              </li>
              <li>
                {decode(t('about2_9'))}: <span className='remark'>{formatDateByLocale(new Date(CONSTANTS.miningEnds), i18n.language)}</span>;
              </li>
              <li>
                {decode(t('about2_10'))};
              </li>
              <li>
                {decode(t('about2_11'))}. {decode(t('about2_12'))}. {decode(t('about2_13'))};
              </li>
              <li>
                {decode(t('about2_21'))}.
              </li>
            </ul>
            
            <br />

            {decode(t('about2_16'))}. {decode(t('about2_17'))}. {decode(t('about2_18'))}. {decode(t('about2_19'))}. {decode(t('about2_20'))}. 
            
            <br/>
            <br/>

            {decode(t('about2_14'))}. {decode(t('about2_15'))}.
            
            <br/>
            <br/>
            <br/>
            
            {/* ------------------------------------------- */}
            <h3 id="article_3">3. {decode(t('about3_1'))}</h3>

            <br />
            
            <ul>
              <li>
                <b>{decode(t('about3_2'))}:</b> {decode(t('about3_3'))}.
              </li>
              <li>
                <b>{decode(t('about3_4'))}:</b> {decode(t('about3_5'))}.
              </li>
              <li>
                <b>{decode(t('about3_6'))}:</b> {decode(t('about3_7'))}. {decode(t('about3_8'))} &#128512;
              </li>
              <li>
                <b>{decode(t('about3_9'))}:</b> {decode(t('about3_10'))}.
              </li>
            </ul>
            
            <br/>

            {decode(t('about3_11'))}. {decode(t('about3_12'))}. {decode(t('about3_13'))}. {decode(t('about3_14'))}. {decode(t('about3_15'))}.

            <br/>
            <br/>

            {decode(t('about3_16'))}. {decode(t('about3_17'))}.

            <br />
            <br />
            <br />

            {/* ------------------------------------------- */}
            <h3 id="article_4">4. {decode(t('about4_1'))}</h3>

            <br />

            {decode(t('about4_2'))}. {decode(t('about4_3'))}.
            
            <br />
            <br />

            {decode(t('about4_4'))}.

            <br />
            <br />
            <br />

            {/* ------------------------------------------- */}
            <h3 id="article_5">5. {decode(t('about5_1'))}</h3>

            <br />

            {decode(t('about5_2'))} <span className='remark'>{formatDateByLocale(new Date(CONSTANTS.miningEnds), i18n.language)}</span> {decode(t('about5_3'))}. {decode(t('about5_4'))}.
            
            <br />
            <br />

            {decode(t('about5_5'))} &ndash; {getDaysDifference(new Date(), new Date(CONSTANTS.miningEnds)).toString()}.

            <br />
            <br />

            {decode(t('about5_6'))} {decode(t('about5_7'))}. {decode(t('about5_8'))}.
            
            <br />
            <br />
            <br />
            
            <hr />
            
            <br />
            <br />

            {decode(t('about6_1'))}. {decode(t('about6_2'))}. {decode(t('about6_3'))}.
            
            <br />
            <br />

            {decode(t('about6_4'))}

          </div>

      </div>

      {/* Компонент навигации */}
      <FooterNavigation />
      
    </div>
  )
}