// -------------------------------------
// Страница первоначального запуска
// -------------------------------------

import React, { useEffect, useState, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { config } from './config';
import { UserProvider, useUser } from './contexts/context';
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import { useTranslation } from 'react-i18next';

import { BootPage } from './pages/boot';
import { MaintenancePage } from './pages/maintenance';
import { AirdropPage } from './pages/airdrop';
import { TapPage } from './pages/tap';
import { BoostPage } from './pages/boost';
import { FriendsPage } from './pages/friends';
import { EarnPage } from './pages/earn';
import { AboutPage } from './pages/about';

import DynamicFavicon from './components/dynamicFavicon';
import './App.css';
import './styles/style.scss';
import CONSTANTS from './components/constants';

import { ReactComponent as LogoIcon } from './assets/skyrun_inline_cloud.svg';

function App() {
  
  const consoleAllowed = true;
  const alertAllowed = false;

  // Загружаем переводы
  const { t } = useTranslation();

  const [isOffline, setIsOffline] = useState<boolean>(false);


  // Какую страницу запускать
  const getStartPage = () => {
    switch (config.startPage) {
      case 'maintenance':
        return <Navigate to="/maintenance" />;
      case 'airdrop':
        return <Navigate to="/airdrop" />;
      default:
        return <Navigate to="/boot" />;
    }
  };

  
  // Если пропала связь с Интернетом
  useEffect(() => {
    const handleOnline = () => {
      setIsOffline(false);
    };

    const handleOffline = () => {
      setIsOffline(true);
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    if (!navigator.onLine) {
      setIsOffline(true);
    }

    // Очистка listeners при размонтировании компонента
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);


  // Обработчики событий, работающие с контекстом
  // Так сделано, чтобы получить доступ к контексту на этой странице
  function EventHandlers() {

    // const {user, sendUserDataToServer} = useUser();
    
    // // ВРЕМЕННО ЗАКОММЕНТИРОВАЛ (для удобства отладки, все работает)
    // // Если пользователь уходит из программы (переключается или закрывает) - 
    // // обновляем данные на сервере.
    // // Но в дополнение ко всему обновление данных на сервере будет происходить и при изменении
    // // контекстных данных.
    // useEffect(() => {
    //   const handleVisibilityChange = () => {
    //     if (document.visibilityState === "hidden" && user) {
    //       // Страница стала невидимой, отправляем данные на сервер
    //       if (consoleAllowed) console.log("[APP.useEffect] handleVisibilityChange");
    //       if (alertAllowed) alert("[APP.useEffect] handleVisibilityChange");

    //       console.log("[APP.useEffect] user.telegramUserID:", user.telegramUserID);

    //       sendUserDataToServer();
    //     }
    //   };

    //   // Убрал это событие, потому что пока даже отвлечение на окно alert срабатывает...
    //   // const handleBlur = () => {
    //   //   // Страница теряет фокус
    //   //   if (consoleAllowed) console.log("[APP.useEffect] handleBlur");
    //   //   if (alertAllowed) alert("[APP.useEffect] handleBlur");
    //   //   sendUserDataToServer();
    //   // };

    //   // Добавляем слушатель события visibilitychange
    //   document.addEventListener("visibilitychange", handleVisibilityChange);
    //   //window.addEventListener("blur", handleBlur);

    //   // Убираем слушатель при размонтировании компонента
    //   return () => {
    //     document.removeEventListener("visibilitychange", handleVisibilityChange);
    //     //window.removeEventListener("blur", handleBlur);
    //   };
    // }, [user, sendUserDataToServer]);
    
    return null; // Этот компонент нужен только для регистрации событий
  }

  return (
    <>
      {isOffline && (
        <div className='offline'>
          <div className='offlineContent'>
            <LogoIcon
              className="bigger-logo img" onContextMenu={(e) => e.preventDefault()} onDragStart={(e) => e.preventDefault()} >
            </LogoIcon>
            <br/>
            <br/>
            {t("noInternetConnection")}
          </div>
        </div>
      )}

      {/* Остальной контент приложения */}
      <TonConnectUIProvider manifestUrl={CONSTANTS.server + '/tonconnect-manifest.json'}>
        <UserProvider>
          <DynamicFavicon />
          <Router>
            <Routes>
              <Route path="/" element={getStartPage()} />
              <Route path="/boot" element={<BootPage />} />
              <Route path="/maintenance" element={<MaintenancePage />} />
              <Route path="/airdrop" element={<AirdropPage />} />
              <Route path="/tap" element={<TapPage />} />
              <Route path="/boost" element={<BoostPage />} />
              <Route path="/friends" element={<FriendsPage />} />
              <Route path="/earn" element={<EarnPage />} />
              <Route path="/about" element={<AboutPage />} />
            </Routes>
          </Router>
          {/* Компонент для обработки событий */}
          <EventHandlers />
        </UserProvider>
      </TonConnectUIProvider>
    </>
  );

}

export default App;
