// -------------------------------------
// Страница скиллов
// -------------------------------------

import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useNavigate } from 'react-router-dom';
import { useUser, UserState, SkillsState, DailyBoostsState } from '../contexts/context';
import { Modal } from "../components/modals";
import { ActiveBoost, formatNumberIntl } from "../components/utils";
import { useTranslation } from 'react-i18next';
import { checkPayment, initiatePayment } from "../components/tonPayment";
import { useTonConnectUI } from "@tonconnect/ui-react";
import FooterNavigation from '../components/footerNavigation';
import CONSTANTS from "../components/constants";
import Snackbar from "../components/snackbar";

import { ReactComponent as BoostIcon} from '../assets/boost.svg';
import { ReactComponent as TapMasterIcon} from '../assets/flame.svg'; // https://thenounproject.com/icon/flame-6479659/
import { ReactComponent as RenewIcon} from '../assets/renew.svg'; // https://thenounproject.com/icon/power-recycling-4235326/
import { ReactComponent as TapIcon} from '../assets/tap.svg';
import { ReactComponent as SpeedometerIcon} from '../assets/speedometer.svg'; // https://thenounproject.com/icon/speedometer-7089878/
import { ReactComponent as PlugIcon} from '../assets/plug.svg'; // https://thenounproject.com/icon/plug-in-1495003/
import { ReactComponent as EnergyIcon} from '../assets/energy.svg';
import { ReactComponent as BotIcon} from '../assets/bot.svg'; // https://thenounproject.com/icon/chatbot-face-6965899/
import { ReactComponent as PaidOptionIcon} from '../assets/paid-option.svg'; // https://thenounproject.com/icon/star-17726/

export function BoostPage() {

  const consoleAllowed = true;
  const alertAllowed = true;

  const navigate = useNavigate();
  
  // Используемые переменные
  const { 
    user, 
    dailyBoosts,
    skills,
    setUser,
    setSkills,
    setDailyBoosts,
    initiateTonPayment,
    setSunraysVisible,
    tonInvestment,
    setTonInvestment,
  } = useUser();

  // Состояние для отслеживания, какое модальное окно активно
  const [activeModal, setActiveModal] = useState<string | null>(null);

  // Загружаем переводы
  const {t} = useTranslation();

  // Действующий буст
  const activeBoost = ActiveBoost(skills.boostX2, skills.boostX3);

  // Снекбар
  const [isSnackbarVisible, setSnackbarVisible] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const handleSnackbarClose = () => {
    if (consoleAllowed) console.log("[BOOST.handleSnackbarClose] Вызвано сокрытие снекбара");
    setSnackbarVisible(false);
  };

  // Добавляем новое состояние для хранения значения инвестиции
  const [modalInputValue, setModalInputValue] = useState<number>(0); 

  // Функция для обновления значения из модального окна
  // Без этой функции не работает получение значения в activateShareholderVote
  const handleModalInputChange = (value: number) => {
    setTonInvestment(value);
  };

  // Находим индексы текущих уровней навыков
  const currentIndexes = ({
    tapIncrement: CONSTANTS.tapIncrementRates.indexOf(skills.tapIncrement || CONSTANTS.iniTapIncrement),
    scoreIncrement: CONSTANTS.scoreIncrementRates.indexOf(skills.scoreIncrement || CONSTANTS.iniScoreIncrement),
    energyLimit: CONSTANTS.energyLimitRates.indexOf(skills.energyLimit || CONSTANTS.iniEnergyLimit),
    energyRestoration: CONSTANTS.energyRestorationRates.indexOf(skills.energyRestoration || CONSTANTS.iniEnergyRestoration),
    energyConsumption: CONSTANTS.energyConsumptionRates.indexOf(skills.energyConsumption || CONSTANTS.iniEnergyConsumption),
    tapBot: CONSTANTS.tapBotRates.indexOf(skills.tapBot || CONSTANTS.iniTapBot),
    boostX2: CONSTANTS.boostX2Rates.indexOf(skills.boostX2 || CONSTANTS.iniBoostX2),
    boostX3: CONSTANTS.boostX3Rates.indexOf(skills.boostX3 || CONSTANTS.iniBoostX3),
    shareholderVote: CONSTANTS.shareholderVoteRates.indexOf(skills.shareholderVote || CONSTANTS.iniShareholderVote),
  });

  // Определяем следующий уровень навыков (если уровень не максимальный)    
  const nextLevels = ({
    tapIncrement: 
      currentIndexes.tapIncrement < CONSTANTS.tapIncrementRates.length - 1 
      ? CONSTANTS.tapIncrementRates[currentIndexes.tapIncrement + 1] 
      : CONSTANTS.maxLevel,
    scoreIncrement: 
      currentIndexes.scoreIncrement < CONSTANTS.scoreIncrementRates.length - 1 
      ? CONSTANTS.scoreIncrementRates[currentIndexes.scoreIncrement + 1] 
      : CONSTANTS.maxLevel,
    energyLimit: 
      currentIndexes.energyLimit < CONSTANTS.energyLimitRates.length - 1 
      ? CONSTANTS.energyLimitRates[currentIndexes.energyLimit + 1] 
      : CONSTANTS.maxLevel,
    energyRestoration: 
      currentIndexes.energyRestoration < CONSTANTS.energyRestorationRates.length - 1 
      ? CONSTANTS.energyRestorationRates[currentIndexes.energyRestoration + 1] 
      : CONSTANTS.maxLevel,
    energyConsumption: 
      currentIndexes.energyConsumption < CONSTANTS.energyConsumptionRates.length - 1 
      ? CONSTANTS.energyConsumptionRates[currentIndexes.energyConsumption + 1] 
      : CONSTANTS.maxLevel
  });

  // Цены апгрейдов
  const prices = ({
    tapIncrement: 
        currentIndexes.tapIncrement < CONSTANTS.tapIncrementCosts.length - 1 
        ? CONSTANTS.tapIncrementCosts[currentIndexes.tapIncrement + 1] 
        : CONSTANTS.maxLevel,
    scoreIncrement: 
        currentIndexes.scoreIncrement < CONSTANTS.scoreIncrementCosts.length - 1 
        ? CONSTANTS.scoreIncrementCosts[currentIndexes.scoreIncrement + 1] 
        : CONSTANTS.maxLevel,
    energyLimit: 
      currentIndexes.energyLimit < CONSTANTS.energyLimitCosts.length - 1 
      ? CONSTANTS.energyLimitCosts[currentIndexes.energyLimit + 1] 
      : CONSTANTS.maxLevel,
    energyRestoration: 
      currentIndexes.energyRestoration < CONSTANTS.energyRestorationCosts.length - 1 
      ? CONSTANTS.energyRestorationCosts[currentIndexes.energyRestoration + 1] 
      : CONSTANTS.maxLevel,
    energyConsumption: 
      currentIndexes.energyConsumption < CONSTANTS.energyConsumptionCosts.length - 1 
      ? CONSTANTS.energyConsumptionCosts[currentIndexes.energyConsumption + 1] 
      : CONSTANTS.maxLevel,
    tapBot: 
      !skills.tapBot 
      ? CONSTANTS.tapBotCosts[1] 
      : CONSTANTS.maxLevel,
    boostX2: 
      !skills.boostX2 
      ? CONSTANTS.boostX2Costs[1] 
      : CONSTANTS.maxLevel,
    boostX3: 
      !skills.boostX3 
      ? CONSTANTS.boostX3Costs[1] 
      : CONSTANTS.maxLevel,
    shareholderVote: 
      !skills.shareholderVote 
      ? CONSTANTS.shareholderVoteCosts[1] 
      : CONSTANTS.maxLevel,
  });

  // Интерпретации значений скиллов
  const readableValues = ({
    tapBot: !skills.tapBot ? '' : t("active"),
    boostX2: !skills.boostX2 ? '' : t("active"),
    boostX3: !skills.boostX3 ? '' : t("active"),
    shareholderVote: !skills.shareholderVote ? '' : t("submitted"),
  });

  // Функция для открытия конкретного модального окна
  const openModal = (modalType: string) => {
    setActiveModal(modalType); // Устанавливаем активное модальное окно
  };

  // Функция для закрытия модального окна
  const closeModal = () => {
    setActiveModal(null); // Закрываем все модальные окна
  };

  // ---------------------------------------------------
  // АКТИВАТОРЫ

  // Активация TapMaster
  const activateTapMaster = () => {
    if (dailyBoosts.tapMaster > 0) {

      // Включаем визуальную подсветку
      setSunraysVisible(true);

      setSkills((prev) => ({
        ...prev,
        activeTapIncrement: prev.tapIncrement * activeBoost * CONSTANTS.tapMasterBoostRatio,
      }));

      setDailyBoosts((prev) => ({
        ...prev,
        tapMaster: prev.tapMaster - 1,
        energyFrozen: true,
      }));


      // Возврат к стандартному значению через 20 секунд
      setTimeout(() => {

        setSkills((prev) => ({
          ...prev,
          activeTapIncrement: prev.tapIncrement * activeBoost,
        }));

        setDailyBoosts((prev) => ({
          ...prev,
          energyFrozen: false,
        }));

        // Выключаем визуальную подсветку
        setSunraysVisible(false);

      }, CONSTANTS.tapMasterBoostSeconds * 1000);

    }
  };

  // Активация Full Tank
  const activateFullTank = () => {
    if (dailyBoosts.fullTank > 0) {

      setDailyBoosts((prev) => ({
        ...prev,
        energy: skills.energyLimit,
        fullTank: prev.fullTank - 1
      }));

    }
  };

  // Активация TapIncrement
  const activateTapIncrement = () => {
    if (currentIndexes.tapIncrement < CONSTANTS.tapIncrementRates.length - 1) {

      const newTapIncrement = CONSTANTS.tapIncrementRates[currentIndexes.tapIncrement + 1];

      setUser((prev) => ({
          ...prev,
          score: prev.score - CONSTANTS.tapIncrementCosts[currentIndexes.tapIncrement + 1],
      }));

      setSkills((prev) => ({
        ...prev,
        tapIncrement: newTapIncrement,
        activeTapIncrement: newTapIncrement,
      }))
    }
  };

  // Функция активации прокачиваемого бустера ScoreIncrement
  const activateScoreIncrement = () => {
    if (currentIndexes.scoreIncrement < CONSTANTS.scoreIncrementRates.length - 1) {

      setUser((prev) => ({
        ...prev,
        score: prev.score - CONSTANTS.scoreIncrementCosts[currentIndexes.scoreIncrement + 1],
      }));

      setSkills((prev) => ({
        ...prev,
        scoreIncrement: CONSTANTS.scoreIncrementRates[currentIndexes.scoreIncrement + 1],
      }));
      
    }
  };

  // Функция активации прокачиваемого бустера EnergyLimit
  const activateEnergyLimit = () => {
    if (currentIndexes.energyLimit < CONSTANTS.energyLimitRates.length - 1) { 

      setUser((prev) => ({
        ...prev,
        score: prev.score - CONSTANTS.energyLimitCosts[currentIndexes.energyLimit + 1],
      }));

      setSkills((prev) => ({
        ...prev,
        energyLimit: CONSTANTS.energyLimitRates[currentIndexes.energyLimit + 1],
      }));

    }
  };

  // Функция активации прокачиваемого бустера EnergyResoration
  const activateEnergyRestoration = () => {
    if (currentIndexes.energyRestoration < CONSTANTS.energyRestorationRates.length - 1) {
      
      setUser((prev) => ({
        ...prev,
        score: prev.score - CONSTANTS.energyRestorationCosts[currentIndexes.energyRestoration + 1],
      }));

      setSkills((prev) => ({
        ...prev,
        energyRestoration: CONSTANTS.energyRestorationRates[currentIndexes.energyRestoration + 1],
      }));

    }
  };

  // Функция активации прокачиваемого бустера EnergyResoration
  const activateEnergyConsumption = () => {
    if (currentIndexes.energyConsumption < CONSTANTS.energyConsumptionRates.length - 1) {

      setUser((prev) => ({
        ...prev,
        score: prev.score - CONSTANTS.energyConsumptionCosts[currentIndexes.energyConsumption + 1],
      }));

      setSkills((prev) => ({
        ...prev,
        energyConsumption: CONSTANTS.energyConsumptionRates[currentIndexes.energyConsumption + 1],
      }));

    }
  };

  // Функция активации прокачиваемого бустера TapBot
  const activateTapBot = () => {
    if (currentIndexes.tapBot < CONSTANTS.tapBotRates.length - 1) {

      setUser((prev) => ({
        ...prev,
        score: prev.score - CONSTANTS.tapBotCosts[currentIndexes.tapBot + 1],
      }));

      setSkills((prev) => ({
        ...prev,
        tapBot: CONSTANTS.tapBotRates[currentIndexes.tapBot + 1],
      }));

    }
  };

  // Активация BoostX2
  const activateBoostX2 = () => {
    if (currentIndexes.boostX2 < CONSTANTS.boostX2Rates.length - 1) {

      setSkills((prev) => ({
        ...prev,
        boostX2: CONSTANTS.boostX2Rates[currentIndexes.boostX2 + 1],
      }));

    }
  };

  // Активация BoostX3
  const activateBoostX3 = () => {
    if (currentIndexes.boostX3 < CONSTANTS.boostX3Rates.length - 1) {

      setSkills((prev) => ({
        ...prev,
        boostX3: CONSTANTS.boostX3Rates[currentIndexes.boostX3 + 1],
      }));

    }
  };

  // Функция активации Shareholder
  const activateShareholderVote = () => {
    if (consoleAllowed) console.log("[BOOST.activateShareholderVote] tonInvestment:", tonInvestment);
    if (currentIndexes.shareholderVote < CONSTANTS.shareholderVoteRates.length - 1) {

      setSkills((prev) => ({
        ...prev,
        shareholderVote: CONSTANTS.shareholderVoteRates[currentIndexes.shareholderVote + 1],
      }));

    }
  };

  // ---------------------------------------------------
  // Функция обработки нажатия на кнопку
  const handleClick = useCallback(async (id: string) => {
    if (consoleAllowed) console.log('[BOOST] Clicked:', id);
      switch (id) {
        case 'tapMaster':
          activateTapMaster();
          break;
        case 'fullTank':
          activateFullTank();
          break;
        case 'tapIncrement':
          activateTapIncrement();
          break;
        case 'scoreIncrement':
          activateScoreIncrement();
          break;
        case 'energyLimit':
          activateEnergyLimit();
          break;
        case 'energyRestoration':
          activateEnergyRestoration();
          break;
        case 'energyConsumption':
          activateEnergyConsumption();
          break;
        case 'tapBot':
          activateTapBot();
          break;
        case 'boostX2':
          activateBoostX2();
          break;
        case 'boostX3':
          activateBoostX3();
          break;
        case 'shareholderVote':
          activateShareholderVote();
          break;
        default:
          break;
      }
      closeModal();

      // Временно закомментировано для проверки обновления контекста
      if (id !== 'boostX2' && id !== 'boostX3' && id !== 'shareholderVote') navigate('/tap');

    }, []
  );

  

  // const [paymentStatus, setPaymentStatus] = useState<string | null>(null);

  // const handlePaymentComplete = (success: boolean) => {
  //   setPaymentStatus(success ? "@Платеж успешно завершен" : "@Платеж не удался");
  //   closeModal();
  // };

  // Обработка оплаты
  const handlePaymentClick = async (transactionTonValue: number, transactionMessage: string) => {
    
    if (consoleAllowed) console.log("[BOOST.handlePaymentClick] transactionTonValue:", transactionTonValue);
    if (consoleAllowed) console.log("[BOOST.handlePaymentClick] transactionMessage:", transactionMessage);

    try {
      if (consoleAllowed) console.log("[BOOST.handlePaymentClick] @Initiating payment...");

      // ---------------------------
      const isPaid = await initiateTonPayment(transactionTonValue, transactionMessage);
      //const isPaid = true; // Для тестирования

      if (isPaid) {
        // Проверка оплаты
        //const isConfirmed = await checkPayment(transactionMessage, transactionTonValue); // Реальная работа
        const isConfirmed = await checkPayment('428856366_boostX2', 0.1); // Для тестирования
        
        if (isConfirmed) {
          if (consoleAllowed) console.log("[BOOST.handlePaymentClick] @Payment confirmed");
          activateBoostX2();
          closeModal();
        } else {
          if (consoleAllowed) console.error("[BOOST.handlePaymentClick] @Payment not found");

          // Показываем сообщение об ошибке
          setSnackbarMessage(t("paymentNotConfirmed"));
          setSnackbarVisible(true);
        }

      } else {
        if (consoleAllowed) console.error("[BOOST.handlePaymentClick] @Payment error");

        // Показываем сообщение об ошибке
        setSnackbarMessage(t("paymentError"));
        setSnackbarVisible(true);
      }
      // ---------------------------

    } catch (error) {
      if (consoleAllowed) console.error("[BOOST.handlePaymentClick] @Error during payment:", error);
    };

    // Подставляется вместо закомментированной части выше
    // ---------------------------
    // ТЕСТИРОВАНИЕ ОПЛАТЫ
    // Внимамние: фиксированный ответ в tonPayment.tsx
    // const isConfirmed = await checkPayment('428856366_boostX2', 0.5); // Для тестирования
        
    // if (isConfirmed) {
    //   if (consoleAllowed) console.log("[BOOST.handlePaymentClick] @Payment confirmed");
    //   activateBoostX2();
    //   closeModal();
    // } else {
    //   if (consoleAllowed) console.error("[BOOST.handlePaymentClick] @Payment not found");

    //   // Показываем сообщение об ошибке
    //   setSnackbarMessage(t("paymentNotConfirmed"));
    //   setSnackbarVisible(true);
    // }
    // ---------------------------
  

  };

  // ---------------------------------------
  // Модальные окна

  const modalsMap: { [key: string]: JSX.Element } = {
    tapMaster: (
      <Modal
        locale={user.locale || CONSTANTS.defaultLocale}
        id="tapMaster"
        type="dailies"
        title={t("tapMaster")}
        icon={<TapMasterIcon className="modal-img" />}
        brief={t("boostTappingForAWhile")}
        description={t("thriceADayYouMayBoostTapping")}
        currentLevel={dailyBoosts.tapMaster}
        maxLevel={CONSTANTS.tapMasterMax}
        buttonText={t("activate")}
        score={user.score}
        onClose={closeModal}
        onClick={() => handleClick('tapMaster')}
      />
    ),
    fullTank: (
      <Modal
        locale={user.locale || CONSTANTS.defaultLocale}
        id="fullTank"
        type="dailies"
        title={t("fullTank")}
        icon={<RenewIcon className="modal-img" />}
        brief={t("rechargeEnergyToKeepTapping")}
        description={t("thriceADayYouMayBoostTapping")}
        currentLevel={dailyBoosts.fullTank}
        maxLevel={CONSTANTS.fullTankMax}
        buttonText={t("recharge")}
        score={user.score}
        onClose={closeModal}
        onClick={() => handleClick('fullTank')}
      />
    ),
    tapIncrement: (
      <Modal
        locale={user.locale || CONSTANTS.defaultLocale}
        id="tapIncrement"
        type="levels"
        title={t("tapIncrement")}
        icon={<TapIcon className="modal-img" />}
        brief={t("earnMoreEachTimeYouTap")}
        description=""
        bought={t("maxedOut")}
        decimals={5}
        currentLevel={skills.tapIncrement}
        sec=""
        nextLevel={nextLevels.tapIncrement}
        price={prices.tapIncrement}
        currency={CONSTANTS.tapIncrementCurrency}
        buttonText={t("upgrade")}
        score={user.score}
        onClose={closeModal}
        onClick={() => handleClick('tapIncrement')}
      />
    ),
    scoreIncrement: (
      <Modal
        locale={user.locale || CONSTANTS.defaultLocale}
        id="scoreIncrement"
        type="levels"
        title={t("scoreIncrement")}
        icon={<SpeedometerIcon className="modal-img" />}
        brief={t("earnMoreEverySecond")}
        description=""
        bought={t("maxedOut")}
        decimals={5}
        currentLevel={skills.scoreIncrement}
        sec={t("perSec")}
        nextLevel={nextLevels.scoreIncrement}
        price={prices.scoreIncrement}
        currency={CONSTANTS.scoreIncrementCurrency}
        buttonText={t("upgrade")}
        score={user.score}
        onClose={closeModal}
        onClick={() => handleClick('scoreIncrement')}
      />
    ),
    energyLimit: (
      <Modal
        locale={user.locale || CONSTANTS.defaultLocale}
        id="energyLimit"
        type="levels"
        title={t("energyLimit")}
        icon={<EnergyIcon className="modal-img" />}
        brief={t("moreEnergyToTap")}
        description=""
        bought={t("maxedOut")}
        decimals={0}
        currentLevel={skills.energyLimit}
        sec=""
        nextLevel={nextLevels.energyLimit}
        price={prices.energyLimit}
        currency={CONSTANTS.energyLimitCurrency}
        buttonText={t("upgrade")}
        score={user.score}
        onClose={closeModal}
        onClick={() => handleClick('energyLimit')}
      />
    ),
    energyRestoration: (
      <Modal
        locale={user.locale || CONSTANTS.defaultLocale}
        id="energyRestoration"
        type="levels"
        title={t("energyRestoration")}
        icon={<PlugIcon className="modal-img" />}
        brief={t("lessBreaksBetweenTaps")}
        description=""
        bought={t("maxedOut")}
        decimals={0}
        currentLevel={skills.energyRestoration}
        sec={t("perSec")}
        nextLevel={nextLevels.energyRestoration}
        price={prices.energyRestoration}
        currency={CONSTANTS.energyRestorationCurrency}
        buttonText={t("upgrade")}
        score={user.score}
        onClose={closeModal}
        onClick={() => handleClick('energyRestoration')}
      />
    ),
    energyConsumption: (
      <Modal
        locale={user.locale || CONSTANTS.defaultLocale}
        id="energyConsumption"
        type="levels"
        title={t("energyConsumption")}
        icon={<PlugIcon className="modal-img" />}
        brief={t("lessTapsToDrainEnergy")}
        description=""
        bought={t("maxedOut")}
        decimals={0}
        currentLevel={skills.energyConsumption}
        //sec={t("perSec")}
        nextLevel={nextLevels.energyConsumption}
        price={prices.energyConsumption}
        currency={CONSTANTS.energyConsumptionCurrency}
        buttonText={t("upgrade")}
        score={user.score}
        onClose={closeModal}
        onClick={() => handleClick('energyConsumption')}
      />
    ),
    tapBot: (
      <Modal
        locale={user.locale || CONSTANTS.defaultLocale}
        id="tapBot"
        type="booleans"
        paid="bot"
        title={t("tapBot")}
        icon={<BotIcon className="modal-img" />}
        brief={t("earnWhileYoureOff")}
        description={t("theBotMinesYourRewardFor8Hours") + ". " + t("whenTheTimeRunsOutItStops")}
        bought={t("theBotDoesMiningForYou") + ". " + t("dontForgetToClaimYourReward")}
        warning=""
        currentLevelBoolean={skills.tapBot}
        price={prices.tapBot}
        currency={CONSTANTS.tapBotCurrency}
        buttonText={t("upgrade")}
        score={user.score}
        onClose={closeModal}
        onClick={() => handleClick('tapBot')}
      />
    ),
    boostX2: (
      <Modal
        locale={user.locale || CONSTANTS.defaultLocale}
        id="boostX2"
        type="booleans"
        paid="paid"
        title={t("boostX2")}
        icon={<PaidOptionIcon className="modal-img-paid" />}
        brief=""
        description={t("constantDoublingOfGainsWhenTappingOrMiningWithABot")}
        bought={t("winningsAreDoubledWhenTappingAndMiningWithABot")}
        warning={t("thisIsAPaidOptionButNotMandatory")}
        currentLevelBoolean={skills.boostX2}
        price={prices.boostX2}
        currency={CONSTANTS.boostX2Currency}
        buttonText={t("buy")}
        score={user.score}
        onClose={closeModal}
        onClick={() => handlePaymentClick(prices.boostX2, `${user.telegramUserID}_boostX2`)}
      />
    ),
    boostX3: (
      <Modal
        locale={user.locale || CONSTANTS.defaultLocale}
        id="boostX3"
        type="booleans"
        paid="paid-more"
        title={t("boostX3")}
        icon={<PaidOptionIcon className="modal-img-paid" />}
        brief=""
        description={t("constantTriplingOfGainsWhenTappingOrMiningWithABot")}
        bought={t("winningsAreTripledWhenTappingAndMiningWithABot")}
        warning={t("thisIsAPaidOptionButNotMandatory")}
        currentLevelBoolean={skills.boostX3}
        price={prices.boostX3}
        currency={CONSTANTS.boostX3Currency}
        buttonText={t("buy")}
        score={user.score}
        onClose={closeModal}
        onClick={() => handlePaymentClick(prices.boostX3, `${user.telegramUserID}_boostX3`)}
      />
    ),
    shareholderVote: (
      <Modal
        locale={user.locale || CONSTANTS.defaultLocale}
        id="shareholder"
        type="booleans"
        paid="paid-more"
        title={t("becomeAShareholder")}
        icon={<PaidOptionIcon className="modal-img-paid" />}
        brief={t("keepMakingMoneyWithUs")}
        description={t("weArePlanningToExpandSoWeAreInterestedInSshareholders") + ". " + t("indicateTheAmountOfThePossibleInvestment")}
        bought={t("yourVoiceIsCounted") + ". " + t("weWillRevertSoonest")}
        warning=""
        currentLevelBoolean={skills.shareholderVote}
        price={prices.shareholderVote}
        currency={CONSTANTS.shareholderVoteCurrency}
        buttonText={t("submit")}
        score={user.score}
        onClose={closeModal}
        onClick={() => handleClick('shareholderVote')}
        onInputChange={handleModalInputChange} // Новый проп
      />
    ),
  };

  return (
    <div className="App">

      <div className="container">

        {/* ЗАГОЛОВОК */}
        <div className="boosters-header">
          <div>
            <BoostIcon
                className="image-page-title">
            </BoostIcon>
          </div>
          <div className="description">
            {t("increaseYourRewardsWithTheseTools")}
          </div>
        </div>

        {/* ЕЖЕДНЕВНЫЕ БУСТЕРЫ */}
        <div className="daily-boosters-header">
          <div className="daily-boosters-title">
              {t("freeDailyBoosters")}
          </div>
          {/* <div className="daily-boosters-buttons"> */ }
          <div className="boosters-list">
            
            <div className="button lefted" onClick={() => openModal('tapMaster')}>
              <TapMasterIcon className="a-img-big" />
              <span className="button-span">
                <div className="booster-name">{t("tapMaster")}</div>
                <div className="booster-subtitle">{dailyBoosts.tapMaster} / {CONSTANTS.tapMasterMax}</div>
              </span>
            </div>

            <div className="button lefted" onClick={() => openModal('fullTank')}>
              <RenewIcon className="a-img-big" />
              <span className="button-span">
                <div className="booster-name">{t("fullTank")}</div>
                <div className="booster-subtitle">{dailyBoosts.fullTank} / {CONSTANTS.fullTankMax}</div>
              </span>
            </div>

          </div>
        </div>

        {/* ПРОКАЧИВАЕМЫЕ БУСТЕРЫ */}
        <div className="list boosters-container">
          <div className="daily-boosters-title">
            {t("upgradeableBoosters")}
          </div>
          <div className="boosters-list">
            
            <div className="button lefted" onClick={() => openModal('tapIncrement')}>
              <TapIcon className="a-img-big" />
              <span className="button-span">
                <div className="booster-name">{t("tapIncrement")}</div>
                <div className="booster-subtitle">+{skills.tapIncrement}</div>
              </span>
            </div>
            
            <div className="button lefted" onClick={() => openModal('scoreIncrement')}>
              <SpeedometerIcon className="a-img-big" />
              <span className="button-span">
                <div className="booster-name">{t("scoreIncrement")}</div>
                <div className="booster-subtitle">+{skills.scoreIncrement} {/*/@sec*/}</div>
              </span>
            </div>

            <div className="button lefted" onClick={() => openModal('energyLimit')}>
              <EnergyIcon className="a-img-big" />
              <span className="button-span">
                <div className="booster-name">{t("energyLimit")}</div>
                <div className="booster-subtitle">{formatNumberIntl(user.locale || CONSTANTS.defaultLocale, skills.energyLimit || CONSTANTS.iniEnergyLimit, 0)}</div>
              </span>
            </div>
            
            <div className="button lefted" onClick={() => openModal('energyRestoration')}>
              <PlugIcon className="a-img-big" />
              <span className="button-span">
                <div className="booster-name">{t("energyRestoration")}</div>
                <div className="booster-subtitle">+{skills.energyRestoration} {/*/@sec*/}</div>
              </span>
            </div>

            <div className="button lefted" onClick={() => openModal('energyConsumption')}>
              <PlugIcon className="a-img-big" />
              <span className="button-span">
                <div className="booster-name">{t("energyConsumption")}</div>
                <div className="booster-subtitle">x{skills.energyConsumption} {/*/@sec*/}</div>
              </span>
            </div>

            <div className="button lefted a-bot" onClick={() => openModal('tapBot')}>
              <BotIcon className="a-img-big" />
              <span className="button-span">
                <div className="booster-name">{t("tapBot")}</div>
                <div className="booster-subtitle">{readableValues.tapBot}</div>
              </span>
            </div>

            <div className="button lefted a-paid-option" onClick={() => openModal('boostX2')}>
              <PaidOptionIcon className="a-img-paid" />
              <span className="button-span">
                <div className="booster-name">{t("boostX2")}</div>
                <div className="booster-subtitle">{readableValues.boostX2}</div>
              </span>
            </div>

            { /*
            <div className="button lefted a-paid-option" href="#" onClick={() => openModal('boostX3')}>
              <PaidOptionIcon className="a-img-paid" />
              <span className="button-span">
                <div className="booster-name">{t("boostX3")}</div>
                <div className="booster-subtitle">{readableValues.boostX3}</div>
              </span>
            </div>
            */ }

            <div className="button lefted a-paid-option-more" onClick={() => openModal('shareholderVote')}>
              <PaidOptionIcon className="a-img-paid" />
              <span className="button-span">
                <div className="booster-name">{t("becomeAShareholder")}</div>
                <div className="booster-subtitle">{readableValues.shareholderVote}</div>
              </span>
            </div>

          </div>
        </div>

      </div>
      
      {/* Рендер активного модального окна */}
      {activeModal && modalsMap[activeModal]}

      {/* Компонент навигации */}
      <FooterNavigation />
      
      {/* Появляющийся снекбар */}
      <Snackbar
        message={snackbarMessage}
        isVisible={isSnackbarVisible}
        onClose={handleSnackbarClose}
      />

    </div>
  )
}