// -------------------------------------
// Главная страница
// -------------------------------------

// Подключение кошелька TON
// Устанавливаем TonConnect:
// https://docs.ton.org/develop/dapps/ton-connect/react
// npm i @tonconnect/ui-react

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../contexts/context';
import { useTonConnectUI, useTonWallet, useTonAddress } from '@tonconnect/ui-react';
import { Clicker } from "../components/clicker"
import { Score } from "../components/score"
import { Energy } from "../components/energy"
import { EnergyLimit } from "../components/energyLimit"
import { ProgressBar } from "../components/progressBar"
import FooterNavigation from '../components/footerNavigation';
import CONSTANTS from '../components/constants';
import { Modal } from '../components/modals';
import { useTranslation } from 'react-i18next';
import { truncateString } from '../components/utils';
import { DisplayUserName } from '../components/displayUserName';

// Установка пакета react-svg для работы с SVG:
// npm install react-svg

// Для поддержки мультиязычности устанавливаем пакет i18next:
// npm install react-i18next i18next

// Автоматическое определение языка пользователя
// npm install i18next-browser-languagedetector

import { ReactComponent as BotIcon } from '../assets/bot.svg';
import { ReactComponent as LogoIcon } from '../assets/skyrun_inline_cloud.svg';
import { ReactComponent as WalletIcon } from '../assets/wallet.svg'; // https://thenounproject.com/icon/wallet-7381167/
import { ReactComponent as EnergyIcon } from '../assets/energy.svg';

export function TapPage() {

  // Информация для отладки
  const consoleAllowed = false;
  const alertAllowed = false;

  const navigate = useNavigate();

  const { user, skills, dailyBoosts, setUser } = useUser();

  const displayUserName = DisplayUserName();

  // Состояние для управления видимостью модального окна
  const [isModalVisible, setModalVisible] = useState(false);

  // Загружаем переводы
  const { t } = useTranslation();

  const [tonConnectUI, setOptions] = useTonConnectUI();
  const wallet = useTonWallet();
  const userFriendlyAddress = useTonAddress();

  const [showID, setShowID] = useState(false);

  const handleClickName = () => {
    setShowID(prevState => !prevState); // Переключение между именем и номером
  };


  // Отображение модального окна сколько намайнил бот
  useEffect(() => {
    if (user.botMined > 0) {
      setModalVisible(true);
    } else {
      setModalVisible(false);
    }
  }, [user.botMined]);


  // Функция для закрытия модального окна
  const closeBotModal = () => {

    setUser((prev) => ({
      ...prev,
      botMined: 0
    }));

    setModalVisible(false);
  };


  // Если нет данных пользователя - принудительно возвращаемся на их загрузку
  if (!user) {
    if (consoleAllowed) console.log("[TAP.TapPage] @Данных нет, возвращаемся на страницу загрузки");
    if (consoleAllowed) console.log("[TAP.TapPage] ----------------------------------------------");
    navigate('/boot');
  }


  /// Функция для подключения или переподключения кошелька
  const handleWalletConnect = async () => {
    try {
      if (wallet) {
        // Отключаем текущий кошелек перед подключением нового
        await tonConnectUI.disconnect();
      }
      // Открываем модальное окно для подключения нового кошелька
      await tonConnectUI.openModal();
    } catch (error) {
      if (consoleAllowed) console.error("Ошибка подключения TON Connect:", error);
      // Можно добавить уведомление для пользователя об ошибке
      if (alertAllowed) alert("Произошла ошибка подключения. Попробуйте еще раз.");
    }
  };

  
  return (
    <div className="App">

      {/* Модальное окно */}
      <Modal
        locale={user.locale}
        id="botMining"
        type="botMining"
        icon={<BotIcon className="modal-img" />}
        title={t("bot")}
        buttonText={t("ok")}
        value={user.botMined}
        isVisible={isModalVisible}
        onClick={closeBotModal}
        onClose={closeBotModal}
      />

      
      { /*Заголовок: имя пользователя, логотип, адрес кошелька*/}
      <div className="header">
        <div className="account" onClick={handleClickName}>
          <div>
            {user.telegramPhotoUrl !== '' && (
              <img
                src={user.telegramPhotoUrl}
                className='avatar-small'
              />
            )}
          </div>
          <div className='user-name'>
            {showID ? user.telegramUserID : displayUserName}
          </div>
        </div>
        <div>
          <LogoIcon
            className="logo img" onContextMenu={(e) => e.preventDefault()} onDragStart={(e) => e.preventDefault()} >
          </LogoIcon>
        </div>

        {/* Подключение кошелька */}
        <div className="wallet-address" onClick={handleWalletConnect}>
          {
            wallet ? (
              <>
                <WalletIcon
                  className="wallet-small img"
                  onContextMenu={(e) => e.preventDefault()}
                  onDragStart={(e) => e.preventDefault()}
                />
                {truncateString(userFriendlyAddress, CONSTANTS.shortWalletFirstSymbols, CONSTANTS.shortWalletLastSymbols)}
              </>
            ) : (
              t('connectWallet')
            )
          }
        </div>

      </div>

      {/* Общий счет */}
      <Score />

      {/* Облако для кликания */}
      <Clicker />

      {/* Энергия */}
      <div className="energy-container">

        <div className='energy-numbers'>
          {/* Иконка энергии */}
          <EnergyIcon
            className="energy-image img" onContextMenu={(e) => e.preventDefault()} onDragStart={(e) => e.preventDefault()}>
          </EnergyIcon>

          {/* Текущий запас энергии */}
          <Energy /> / <EnergyLimit energyLimit={skills.energyLimit} />
        </div>
        
        <div>
          {/* Progress Bar */}
          <ProgressBar
            current={dailyBoosts.energy}
            limit={skills.energyLimit}
          />
        </div>

      </div>

      {/* Компонент навигации */}
      <FooterNavigation />

    </div>
  )
}
