// -------------------------------------
// Текущий уровень энергии
// -------------------------------------

import { useUser } from '../contexts/context';
import { formatNumberIntl } from "./utils";

export const Energy = () => {
    
    const { user, dailyBoosts } = useUser();

    return (
        <div className='energy'>
            {formatNumberIntl(user.locale, dailyBoosts.energy, 0)}
        </div>
    )
}