// -------------------------------------
// КОНСТАНТЫ
// -------------------------------------

const CONSTANTS = {

    // ================================
    // БУСТЫ

    // ЕЖЕДНЕВНЫЕ БУСТЫ    
    'tapMasterMax': 3, // Количество попыток (всего)
    'fullTankMax': 3, // Количество попыток (всего)
    'tapMasterBoostSeconds': 20, // На 20 секунд увеличивается доходность тапания
    'tapMasterBoostRatio': 5, // Разы увеличения доходности тапания в течение этого времени

    // --------------------------------
    // УРОВНИ ПРОКАЧКИ 

    'tapIncrementRates': [0.00001, 0.00002, 0.00003, 0.00005, 0.00007, 0.00010, 0.00015, 0.00020, 0.00030, 0.00050],
    'tapIncrementCosts': [0,       1,       2,       4,       8,       16,      32,      64,      128,     256], // Токены
    'tapIncrementCurrency': '', // Игровые монеты

    'scoreIncrementRates': [0.00001, 0.00002, 0.00003, 0.00005, 0.00007, 0.00010, 0.00015, 0.00020, 0.00030, 0.00050],
    'scoreIncrementCosts': [0,       1,       2,       4,       8,       16,      32,      64,      128,     256], // Токены
    'scoreIncrementCurrency': '', // Игровые монеты

    'energyLimitRates': [500, 1000, 1500, 2000, 2500, 3000, 3500, 4000, 4500, 5000],
    'energyLimitCosts': [0,   1,    2,    4,    8,    16,   32,   64,   128,  256], // Токены
    'energyLimitCurrency': '', // Игровые монеты
    
    'energyRestorationRates': [1, 2, 3, 4, 5], // Единиц в секунду
    'energyRestorationCosts': [0, 1, 2, 4, 5], // Токены
    'energyRestorationCurrency': '', // В игровых монетах

    'energyConsumptionRates': [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], // Тапов за раз
    'energyConsumptionCosts': [0, 1, 2, 4, 5, 6, 7, 8, 9, 10], // Токены
    'energyConsumptionCurrency': '', // В игровых монетах

    
    'tapBotRates': [false, true], 
    'tapBotCosts': [0, 2], // Токены
    'tapBotCurrency': '', // Игровые монеты

    'boostX2Rates': [false, true],
    'boostX2Costs': [0, 0.5], // TON
    'boostX2Currency': 'TON',

    'boostX3Rates': [false, true],
    'boostX3Costs': [0, 1], // TON
    'boostX3Currency': 'TON',

    'shareholderVoteRates': [false, true],
    'shareholderVoteCosts': [0, 0], // TON
    'shareholderVoteCurrency': '',

    // ================================
    // ИСХОДНЫЕ ЗНАЧЕНИЯ ПРИ РЕГИСТРАЦИИ

    'iniScore': 1, // Количество монет
    'iniScoreIncrement': 0.00001,
    'iniEnergy': 500,
    'iniEnergyLimit': 500,
    'iniEnergyRestoration': 1, // в секунду
    'iniEnergyConsumption': 1, // за раз
    'iniTapMaster': 3, // Оставшееся количество попыток
    'iniFullTank': 3, // Оставшееся количество попыток
    
    // Уровни бустеров
    'iniTapIncrement': 0.00001,
    'iniTapBot': false,
    'iniBoostX2': false,
    'iniBoostX3': false,
    'iniBoost': 0,
    'iniShareholderVote': false,

    // --------------------------------
    // БОНУСНЫЕ КОРОБКИ

    // Сумма, выпадающая из бонусной коробки
    'bigBonusValue': 100,
    'averageBonusValue': 10,
    'smallBonusValue': 1,
    
    // Количество кликов для появления бонусной коробки
    'clicksForBigBonusValue': 30,
    'clicksForAverageBonusValue': 20,
    'clicksForSmallBonusValue': 10,
    
    // --------------------------------
    // ПРОЧИЕ НАСТРОЙКИ
    'defaultLocale': 'en-US',

    'coreUrl': 'https://skyrun.digital', // Адрес указан на странице о нас
    //'telegramBotHref': 'https://t.me/shxRGQo1Vo_bot',
    'telegramBotHref': 'https://t.me/shxRGQo1Vo_bot/test_sr',
    'telegramBotTg': 'tg://t.me/shxRGQo1Vo_bot',
    'telegramBotToken': '7561846121:AAExGO--uQ691i-ZbDuvYvdF6n0It4V5nnQ',
    'telegramContactsUrl': '?start=contacts',
    'server': 'https://discount.skyrun.digital',
    'apiLinkGetUserRecords': '/api/getuserrecords/v1/',
    'apiLinkUpdateUserRecords': '/api/updateuserrecords/v1/',

    'updateUserRecordsOnServerMilliseconds': 60000000, // Каждые 60 секунд обновлять данные пользователя на сервере

    'maxLevel': -1, // -1 будет признаком максимального уровня
    'scoreIncrementMilliseconds': 1000, // 1 секунда (userContext, useGameState)
    //'energyRestorationMilliseconds': 1000, // 1 секунда - может понадобится, если скорость восстановления энергии будет оличаться от 1 секунды 

      // Обрезка длинных строк (tap)
    'shortWalletFirstSymbols': 4, // Первые 4 знака
    'shortWalletLastSymbols': 4, // Последние 4 знака

    'inviteLinkPrefix': '?startapp=r_', // Префикс ссылки приглашения (работает только со startapp)
    'iconChangeMilliseconds': 850, // Как быстро изменяются иконки друг на друга (в друзьях при копировании)

    'popupAnimationMilliseconds': 2000, // Анимация попапа
    'giftAnimationMilliseconds': 1000, // Анимация выпадающего подарка

    // ПЛАТЕЖИ
    // https://docs.ton.org/v3/documentation/smart-contracts/getting-started/testnet
    // https://wallet.ton.org/?testnet=true // Именно здесь кошелек с окончанием адреса
    // https://t.me/testnetstatus

    //'depositTonWalletAddress': 'UQDB9lI335ILIXk-Ksm6DIUd9mWjHIQZ4Gt6UyhWJ5HjXngu', // Адрес для накопления перечисленных TON (кошелек Телеграм)
    'depositTonWalletAddress': '0QCYW-Bv1KRUBDO1dY7-jz-Rtt-3fapqgzEuyC6Ez_Y2CCDS', // TestNet
    // https://docs.ton.org/v3/guidelines/dapps/tutorials/telegram-bot-examples/accept-payments-in-a-telegram-bot-js
    'validationMaxAttempts': 5, // Количество попыток проверки оплаты
    'validationCheckInterval': 2000, // Интервал между проверками оплаты, мс
    'ValidationRecordsLimit': 100, // Сколько записей в ответе (максимум 100)
    'ValidationArchival': true, // Искать ли древние записи (полезно для отладки)
    
    //'getTransactionsUrl': 'https://toncenter.com/api/v2/getTransactions', // Адрес для проверки транзакций
    'getTransactionsUrl': 'https://testnet.toncenter.com/api/v2/getTransactions', // Адрес для проверки транзакций

    // РЕКЛАМА
    // https://docs.adsgram.ai/publisher/get-block-id
    // https://partner.adsgram.ai/units/6019/
    'adsGramBlockID': '6019', // BlockID в AdsGram (идентификатор рекламного места)
    'adReward': 0.01, // Вознаграждение в токенах за просмотр рекламы
    'maxAdsDaily': 10, // Сколько раз в день можно посмотреть рекламы

    // О ПРОЕКТЕ
    'miningEnds': '2025-06-30', // Дата окончания игры

    // Знаки после запятой
    'precisionEnergy': 0, // 0 знаков после запятой (userContext, useGameState)
    'precisionScore': 5, // 5 знаков после запятой (userContext, useGameState)
    'precisionAdReward': 2, // Знаков после запятой для отображения вознаграждения за рекламу
    
}

Object.freeze(CONSTANTS); // Avoid any accidental value change from outside any key

export default CONSTANTS;