// -------------------------------------
// Общий счет накопленных токенов
// -------------------------------------

import { useUser } from '../contexts/context';
import { formatNumberIntl } from './utils';
import { ReactComponent as CoinIcon } from '../assets/coin-sdt.svg';

export const Score = () => {

    const { user } = useUser();

    return (
        <div className="score">
            <CoinIcon className='big-coin img'/>
            {formatNumberIntl(user.locale, user.score)}
        </div>
    )
}