/*================================================ 
    Описательная часть самого кликера 
================================================*/

import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useUser, UserState, DailyBoostsState } from '../contexts/context';
import CONSTANTS from './constants';
import tapping_image from '../assets/cloud.png';
//import sunrays_image from '../assets/sunrays7.png';
//import gift_image from '../assets/gift_sky_blue.png'; // https://www.vecteezy.com/png/38284461-ai-generated-blue-gift-isolated-on-transparent-gift-box-png
//import gift_image from '../assets/gift_deep_blue.png'; // https://www.vecteezy.com/png/38284461-ai-generated-blue-gift-isolated-on-transparent-gift-box-png
import gift_image from '../assets/gift_red.png'; // https://www.vecteezy.com/png/38284461-ai-generated-blue-gift-isolated-on-transparent-gift-box-png
//import gift_image from '../assets/gift_green.png'; // https://www.vecteezy.com/png/38284461-ai-generated-blue-gift-isolated-on-transparent-gift-box-png
//import gift_image from '../assets/gift_purple.png'; // https://www.vecteezy.com/png/38284461-ai-generated-blue-gift-isolated-on-transparent-gift-box-png
import { ActiveBoost } from './utils';

// Адаптивный дизайн координат фиксированной точки (в процентах)
//const targetXPercent = 35; // vw - с учетом строки попапа, это будет почти центр
// Координаты начального тапа ставятся в CSS: moveToFixedPointUp
//const targetYPercent = -15; // vh - где находится слой score (CSS: score) 
const targetYPercent = -10; // vh - где находится слой score (CSS: score). Еще чуть сместил, чтобы не выходило за границы строки
const popupTapOffsetX = -60; // пикселей. Сместил левее, чтобы центр начального попапа совпадал с точкой тапа
//const popupTapOffsetX = -20; // пикселей. Сместил так, чтобы центр начального попапа совпадал с точкой тапа
const popupTapOffsetY = -30; // пикселей. Сместил чуть выше точки тапа. Так красивее

// Добавляем вычисление координаты тени
const shadowYPercent = 33; // vh - примерно высота, на которой находится тень

interface PopupItem {
    id: string
    x: number
    y: number
    value: number
    popupClass: string
}

interface GiftItem {
    id: string;
    x: number;
    y: number;
    clickCount: number;
    touchIdentifier: number;
}

export const Clicker = () => {

    // Информация для отладки
    const consoleAllowed = false;
    const alertAllowed = false;
    
    const {user, setUser, bonus, skills, dailyBoosts, setBonus, setDailyBoosts, sunraysVisible, sendUserDataToServer} = useUser();
    const [popups, setPopups] = useState<PopupItem[]>([])    
    const containerRef = useRef<HTMLDivElement>(null);
    const [targetPixels, setTargetPixels] = useState({ x: 0, y: 0, yShadow: 0 });
    const [gifts, setGifts] = useState<GiftItem[]>([]);

    // Случайчное целое число для разброса подарочных коробок
    const [randomY, setRandomY] = useState<number>(0);

    // ОТКРЫТИЕ ПОДАРКА
    const handleOpenGift = (id: string) => {
        
        if (consoleAllowed) console.log("[CLICKER.handleOpenGift] @Gift opened:", id);
        
        // Найдем коробочку по её id
        const gift = gifts.find(g => g.id === id);
        if (!gift) return; // Если коробочка не найдена, ничего не делаем

        // Используем количество кликов при создании коробочки
        const clickCount = gift.clickCount; 

        if (consoleAllowed) console.log("[CLICKER.handleOpenGift] @clickCount:", clickCount);

        //Какая сумма лежит в бонусной коробочке
        let value = 0;
        let bigBonus = bonus.big;
        let mediumBonus = bonus.medium;
        let smallBonus = bonus.small;
        
        switch (clickCount) {
            case CONSTANTS.clicksForBigBonusValue:
                value = CONSTANTS.bigBonusValue;
                bigBonus++;
                break;

            case CONSTANTS.clicksForAverageBonusValue:
                value = CONSTANTS.averageBonusValue;
                mediumBonus++;
                break;
                
            case CONSTANTS.clicksForSmallBonusValue:
                value = CONSTANTS.smallBonusValue;
                smallBonus++;
                break;
            
            default:
                value = 0;
                break;
        }

        if (consoleAllowed) console.log("[CLICKER.handleOpenGift] value:", value);

        // Создание нового попапа
        const newPopup: PopupItem = {
            id: `bonus-${Date.now()}-${gift.touchIdentifier}`,
            x: gift.x + 40, // Отступ вправо на 40 пикселей (компенсируем ширину картинки)
            y: gift.y + 100,
            value: value,
            popupClass: "popup-bonus"
        };
        setPopups((prev) => [...prev, newPopup]);

        setUser((prev) => ({
            ...prev,
            score: prev.score + Number(value.toFixed(CONSTANTS.precisionScore)),
        }));

        setBonus((prev) => ({
            ...prev,
            big: bigBonus,
            medium: mediumBonus,
            small: smallBonus,
        }));

        // Удаляем выбранный подарок с экрана
        setGifts(prevGifts => prevGifts.filter(gift => gift.id !== id));
    };

    useEffect(() => {
        const updateTargetPixels = () => {
            
            // Вычисляем размер экрана
            const vw = window.innerWidth;
            const vh = window.innerHeight;

            // Вычисляем точные координаты конечной точки перемещения текста попапа
            // Но не для iPad
            //const x = (vw * targetXPercent) / 100; // vw // Для дробных значений
            
            //const x = vw / 2; // vw - прямо ао центру строки // Для целочисленных значений
            // Хорошо работает на всех размеров экранов
            const x = vw / 2 - 50; // vw - прямо ао центру строки // Для целочисленных значений
            
            const y = (vh * targetYPercent) / 100; // vh
            
            // Для коробочки целевая координата будет в зоне тени
            const yShadow = (vh * shadowYPercent) / 100; // + N: можно создавать рандомное расположение коробок

            setTargetPixels({ x, y, yShadow });
          
        };
    
        updateTargetPixels();
        window.addEventListener('resize', updateTargetPixels);
    
        return () => window.removeEventListener('resize', updateTargetPixels);
    }, []); 


    // ОБРАБОТА ТАПАНИЯ
    const handleTouch = useCallback((event: React.TouchEvent<HTMLImageElement>) => {

        if (consoleAllowed) console.log("[CLICKER.handleTouch] tapIncrement:", skills.tapIncrement);
        if (consoleAllowed) console.log("[CLICKER.handleTouch] activeTapIncrement:", skills.activeTapIncrement);

        //if ((dailyBoosts.energy > 0 || sunraysVisible) && containerRef.current) {
        if ((dailyBoosts.energy / skills.energyConsumption >= 1 || sunraysVisible) && containerRef.current) {

            // Определяем размеры контейнера для позиционирования анимации
            const rect = containerRef.current.getBoundingClientRect();
            
            // Обработка каждого нажатия в мультитапе
            Array.from(event.touches).forEach((touch) => {
                
                // Генерируем случайное число в диапазоне 0...20
                const random = Math.floor(Math.random() * 21); // 21 потому что Math.random() возвращает числа от 0 до 0.999...
                if (consoleAllowed) console.log("random:", random);
                setRandomY(random);

                // Значение в попапе
                const value = Number(
                    //(skills.activeTapIncrement * ActiveBoost(skills.boostX2, skills.boostX3))
                    (skills.activeTapIncrement * ActiveBoost(skills.boostX2, skills.boostX3) * skills.energyConsumption)
                    .toFixed(CONSTANTS.precisionScore)
                );
                
                if (consoleAllowed) console.log("[CLICKER.handleTouch] value:", value);

                const touchId: string = `touch-${Date.now()}-${touch.identifier}`;

                // Создание нового попапа
                const newPopup: PopupItem = {
                    id: touchId, // Уникальный ID для каждого касания
                    x: touch.clientX - rect.left,
                    y: touch.clientY - rect.top,
                    value: parseFloat(Number(value).toFixed(CONSTANTS.precisionScore)),
                    popupClass: "popup"
                };

                setPopups((prev) => [...prev, newPopup]);

                // Включен режим tapMaster (расход энергии заморожен)
                if (dailyBoosts.energyFrozen) {

                    setUser((prev) => ({
                        ...prev,
                        score: prev.score + value,
                    }));
                    
                } else {

                    // Когда энергия не заморожена (не включен режми tapMaster)

                    const clicksCount = user.taps + 1;

                    // Проверка на кратность для бонуса
                    let bonusClickThreshold: number | null = null;

                    if (clicksCount % CONSTANTS.clicksForBigBonusValue === 0) {
                        bonusClickThreshold = CONSTANTS.clicksForBigBonusValue;
                    } else if (clicksCount % CONSTANTS.clicksForAverageBonusValue === 0) {
                        bonusClickThreshold = CONSTANTS.clicksForAverageBonusValue;
                    } else if (clicksCount % CONSTANTS.clicksForSmallBonusValue === 0) {
                        bonusClickThreshold = CONSTANTS.clicksForSmallBonusValue;
                    }

                    if (bonusClickThreshold !== null) {
                        const newGift: GiftItem = {
                            id: `gift-${Date.now()}-${touch.identifier}`,
                            x: touch.clientX - rect.left,
                            y: touch.clientY - rect.top,
                            clickCount: bonusClickThreshold,
                            touchIdentifier: touch.identifier
                        };

                        setGifts((prev) => [...prev, newGift]);
                    }

                    setUser((prev) => ({
                        ...prev,
                        score: prev.score + value,
                        taps: prev.taps + 1,
                    }));

                    setDailyBoosts((prev) => ({
                        ...prev,
                        energy: 
                            Math.max(Number(
                                //(prev.energy - 1).toFixed(CONSTANTS.precisionEnergy)
                                (prev.energy - skills.energyConsumption).toFixed(CONSTANTS.precisionEnergy)
                            ), 0),
                    }));
                    
                    if (consoleAllowed) console.log("[CLICKER.handleClick] taps (after):", user.taps);
                
                }

                // Удаление popup после анимации
                setTimeout(() => {
                    setPopups((prev) => prev.filter((popup) => popup.id !== touchId));
                }, CONSTANTS.popupAnimationMilliseconds);
            });
        }
    }, [user, skills, setGifts, setPopups]);
    
    
    // Облако и отображение количества монет при нажатии на него
    return (
        <div className="clickerComponent" ref={containerRef}>

            <div className="clicker">

                <div className={`image-container ${sunraysVisible ? 'boosted' : ''}`}>
                    <img  
                        className="tapping-image"
                        src={tapping_image} 
                        alt={''} 
                        onTouchStart={handleTouch}
                        onContextMenu={(e) => e.preventDefault()} 
                        onDragStart={(e) => e.preventDefault()} 
                    />
                </div>

                {/* Тень от облака */}
                <div className={`cloud-shadow ${sunraysVisible ? 'thin-edges' : 'soft-edges'}`}></div>
                {/* <div className='cloud-shadow soft-edges'></div> */}

            </div>
            
            {/* Попап */}
            {popups.map(popup => (
                <Popup 
                    key={popup.id} 
                    x={popup.x} 
                    y={popup.y} 
                    value={popup.value}
                    targetX={targetPixels.x}
                    targetY={targetPixels.y - 30} // В какую координату летит попап
                    popupClass={popup.popupClass}
                />
            ))}

            {/* Падающие коробочки */}
            {gifts.map((gift) => (
                <GiftBox 
                    key={gift.id} // Используем id как ключ
                    id={gift.id} // Передаем id в GiftBox
                    x={gift.x} 
                    y={gift.y}  
                    yShadow={targetPixels.yShadow + randomY} 
                    clickCount={user.taps}
                    openGift={() => handleOpenGift(gift.id)}
                />
            ))}

        </div>
        
    )
}

interface PopupProps {
    x: number
    y: number
    value: number|undefined
    targetX: number
    targetY: number
    popupClass: string
}

// Что показывается при тапании
const Popup = ({ x, y, value, targetX, targetY, popupClass }: PopupProps) => {

    const [style, setStyle] = useState<React.CSSProperties>({
        // Начальные координаты появления попапа
        left: `${x + popupTapOffsetX}px`, // Центр надписи по центру клика
        top: `${y + popupTapOffsetY}px`, // Поднял чуть выше точки клика (для удобства)
    });

    // Новое
    useEffect(() => {
        
        const translateX = targetX - x;
        const translateY = targetY - y;

        // Задержка для того, чтобы браузер успел применить начальные стили
        requestAnimationFrame(() => {
            setStyle(prevStyle => ({
                ...prevStyle,
                '--target-x': `${translateX+60}px`,
                '--target-y': `${translateY+40}px`,
            }));
        });
    }, [x, y, targetX, targetY]);

    return (
        <>
            {
                typeof value !== 'undefined' && (
                    <div className={popupClass} style={style}>
                        +{value}
                    </div>
                )
            }
        </>
    );
}

// ПОДАРОЧНАЯ КОРОБКА
interface GiftBoxProps {
    id: string;
    x: number;
    y: number;
    yShadow: number;
    clickCount: number;
    openGift: (clickCount: number) => void;
}

const GiftBox = ({ id, x, y, yShadow, clickCount, openGift }: GiftBoxProps) => {
    
    // Сохраняем начальные координаты и целевую позицию в ref
    const positions = useRef({
        startY: y,
        targetY: yShadow - y
    });

    const [style, setStyle] = useState<React.CSSProperties>({
        left: `${x-10}px`, // Устанавливаем позицию клика по X
        //top: `${y}px`, // Устанавливаем позицию клика по Y
        top: `${positions.current.startY}px`, // Не сдвигаем по вертикали!
        opacity: 0, // Начальная непрозрачность
        '--animation-duration': `${CONSTANTS.giftAnimationMilliseconds / 1000}s`, // Передаем длительность в CSS
        transform: 'translateY(0px)' // Начальная трансформация
    } as React.CSSProperties); // Нужно для поддержки кастомных CSS переменных

    const [isAtTarget, setIsAtTarget] = useState(false);

    useEffect(() => {
        // Старт анимации
        requestAnimationFrame(() => {
            setStyle((prev) => ({
                ...prev,
                transform: `translateX(${0}px) translateY(${positions.current.targetY}px)`, // Перемещаем к тени
                opacity: 1, // Плавное появление
            }));

            // После анимации отметим, что коробочка достигла своей цели
            setTimeout(() => {
                setIsAtTarget(true);
            }, CONSTANTS.giftAnimationMilliseconds); // Время совпадает с длительностью анимации
        });
    }, [x, y, yShadow]);

    return (
        <>    
            <img
                src={gift_image}
                alt="Gift"
                className={`gift-box ${isAtTarget ? 'shadow' : ''}`}
                style={{
                    ...style,
                    cursor: isAtTarget ? 'pointer' : 'default',
                }}
                onClick={() => isAtTarget ? openGift(clickCount) : undefined} // Исправленный обработчик клика
            />
        </>
    );
};