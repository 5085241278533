import React, { createContext, useContext, useState, useEffect, useCallback, useRef } from 'react';
import { useTonConnectUI } from '@tonconnect/ui-react';
import { initiatePayment } from '../components/tonPayment';
import { ActiveBoost } from '../components/utils';
import CONSTANTS from '../components/constants';

// Основные типы данных
export interface UserState {
  locale: string;
  telegramUserID: number;
  telegramUsername: string;
  telegramFirstName: string;
  telegramLastName: string;
  telegramIsPremium: boolean;
  telegramPhotoUrl: string;
  telegramIsBot: boolean;
  wallet: string;
  score: number;
  taps: number;
  botMined: number;
  lastSeenActivity: number;
  friends: number;
}

interface BonusState {
    big: number;
    medium: number;
    small: number;
}

export interface SkillsState {
  tapIncrement: number;
  activeTapIncrement: number;
  scoreIncrement: number;
  energyLimit: number;
  energyRestoration: number;
  energyConsumption: number;
  tapBot: boolean;
  boostX2: boolean;
  boostX3: boolean;
  shareholderVote: boolean;
  shareholderVoteTONInvestment: number;
}

export interface DailyBoostsState {
  tapMaster: number;
  fullTank: number;
  adsShown: number;
  energy: number;
  energyFrozen: boolean;
}

export interface FriendsState {
  tgId: number;
  tgUsername: string;
  tgFirstName: string;
  tgLastName: string;
  tgPhotoUrl: string;
  tgIsBot: boolean;
}

interface ContextType {
    // Основные состояния
    user: UserState;
    bonus: BonusState;
    skills: SkillsState;
    dailyBoosts: DailyBoostsState;
    friendsArray: FriendsState[];

    // Методы обновлений
    setUser: React.Dispatch<React.SetStateAction<UserState>>;
    setBonus: React.Dispatch<React.SetStateAction<BonusState>>;
    setSkills: React.Dispatch<React.SetStateAction<SkillsState>>;
    setDailyBoosts: React.Dispatch<React.SetStateAction<DailyBoostsState>>;
    setFriendsArray: React.Dispatch<React.SetStateAction<FriendsState[]>>;
    setSunraysVisible: React.Dispatch<React.SetStateAction<boolean>>;
    setTonInvestment: React.Dispatch<React.SetStateAction<number>>;
    
    // Видимость лучей при мастере тапания
    sunraysVisible: boolean;

    // Инвестиция в монетах TON
    tonInvestment: number;

    // TON-кошелек
    wallet: any;
    userFriendlyAddress: string | null;
    initiateTonPayment: (transactionTonValue: number, transactionMessage: string) => Promise<boolean>;

    sendUserDataToServer: () => void;
}

const UserContext = createContext<ContextType | undefined>(undefined);

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {

  const consoleAllowed = true;
  const alertAllowed = false;

  // Начальные состояния
  const [user, setUser] = useState<UserState>({
    locale: CONSTANTS.defaultLocale,
    telegramUserID: 0,
    telegramUsername: '',
    telegramFirstName: '',
    telegramLastName: '',
    telegramIsPremium: false,
    telegramIsBot: false,
    telegramPhotoUrl: '',
    wallet: '',
    score: CONSTANTS.iniScore,
    taps: 0,
    botMined: 0,
    lastSeenActivity: 0,
    friends: 0,
  });

  const [bonus, setBonus] = useState<BonusState>({
    big: 0,
    medium: 0,
    small: 0,
  });

  const [skills, setSkills] = useState<SkillsState>({
    tapIncrement: CONSTANTS.iniTapIncrement,
    activeTapIncrement: CONSTANTS.iniTapIncrement,
    scoreIncrement: CONSTANTS.iniScoreIncrement,
    energyLimit: CONSTANTS.iniEnergyLimit,
    energyRestoration: CONSTANTS.iniEnergyRestoration,
    energyConsumption: CONSTANTS.iniEnergyConsumption,
    tapBot: CONSTANTS.iniTapBot,
    boostX2: false,
    boostX3: false,
    shareholderVote: false,
    shareholderVoteTONInvestment: 0,
  });

  const [dailyBoosts, setDailyBoosts] = useState<DailyBoostsState>({
    tapMaster: CONSTANTS.iniTapMaster,
    fullTank: CONSTANTS.iniFullTank,
    adsShown: 0,
    energy: CONSTANTS.iniEnergy,
    energyFrozen: false,
  });

  const [friendsArray, setFriendsArray] = useState<FriendsState[]>([]);

  const [sunraysVisible, setSunraysVisible] = useState<boolean>(false);
  const [tonInvestment, setTonInvestment] = useState<number>(0);

  // TON-кошелек
  const [tonConnectUI] = useTonConnectUI();


  // Инициализация TON-платежа
  const initiateTonPayment = useCallback(async (
    transactionTonValue: number, 
    transactionMessage: string
  ): Promise<boolean> => {
    try {
      const success = await initiatePayment(
        transactionTonValue, 
        transactionMessage, 
        tonConnectUI
      );
      
      return success;
    } catch (error) {
      console.error('Payment error:', error);
      return false;
    }
  }, [tonConnectUI]);


  // Таймер обновления счета и энергии (происходит каждую секунду)
  useEffect(() => {
    const gameTimer = setInterval(() => {
      const activeBoost = ActiveBoost(skills.boostX2, skills.boostX3);

      setUser((prev) => ({
        ...prev,
        score: Number(
          prev.score + skills.scoreIncrement * activeBoost
        ),
      }));

      setDailyBoosts((prev) => ({
        ...prev,
        energy: Math.min(
          prev.energy + skills.energyRestoration,
          skills.energyLimit
        )
      }));
    }, 1000);

    return () => clearInterval(gameTimer);
  }, [skills.boostX2, skills.boostX3, skills.scoreIncrement, skills.energyRestoration, skills.energyLimit]);


  // Проверка наступления полуночи по UTC во время работающего приложения
  // Восстанавливаем ежедневные бустеры и отображение рекламы
  // Ежедневный сброс бустеров (время проверяется каждую секунду)
  useEffect(() => {
    const midnightInterval = setInterval(() => {
      
      const now = new Date();
      const utcHours = now.getUTCHours();
      const utcMinutes = now.getUTCMinutes();
      const utcSeconds = now.getUTCSeconds();

      if (utcHours === 0 && utcMinutes === 0 && utcSeconds === 0) {
      //if (utcHours === 21 && utcMinutes === 55 && utcSeconds === 0) {
        if (consoleAllowed) console.log("[CONTEXT] Восстанавливаем ежедневные бустеры");
        if (alertAllowed) alert("[CONTEXT] Восстанавливаем ежедневные бустеры");
        
        setDailyBoosts((prev) => ({
          ...prev,
          tapMaster: CONSTANTS.tapMasterMax,
          fullTank: CONSTANTS.fullTankMax,
          adsShown: 0
        }));
      }
    }, 1000);

    return () => clearInterval(midnightInterval);
  }, [setDailyBoosts]);


  // Отправляем данные на сервер
  const sendUserDataToServer = async () => {
    if (consoleAllowed) console.log("[CONTEXT.sendUserDataToServer] user.telegramUserID:", user.telegramUserID);

    if (user.telegramUserID > 0) {
      const jsonObj = {

        "user": {
          "tgUserId": String(user.telegramUserID),
          "tgUsername": String(encodeURIComponent(String(user.telegramUsername))),
          "tgFirstName": String(encodeURIComponent(String(user.telegramFirstName))),
          "tgLastName": String(encodeURIComponent(String(user.telegramLastName))),
          "tgLocale": String(encodeURIComponent(String(user.locale))),
          "tgIsPremium": String(user.telegramIsPremium),
          "tgIsBot": String(user.telegramIsBot),
          "tgPhotoUrl": String(user.telegramPhotoUrl),

          "score": String(Number(user.score).toFixed(CONSTANTS.precisionScore)),
          "taps": String(user.taps),
          "wallet": String(user.wallet),
        },
        "bonus": {
          "big": String(bonus.big),
          "medium": String(bonus.medium),
          "small": String(bonus.small),
        },
        "dailyBoosts": {
          "tapMaster": String(dailyBoosts.tapMaster),
          "fullTank": String(dailyBoosts.fullTank),
          "adsShown": String(dailyBoosts.adsShown),
          "energy": String(Number(dailyBoosts.energy).toFixed(CONSTANTS.precisionEnergy)),
        },
        "skills": {
          "tapIncrement": String(skills.tapIncrement),
          "scoreIncrement": String(skills.scoreIncrement),
          "energyLimit": String(skills.energyLimit),
          "energyRestoration": String(skills.energyRestoration),
          "energyConsumption": String(skills.energyConsumption),
          "tapBot": String(skills.tapBot),
          "boostX2": String(skills.boostX2),
          "boostX3": String(skills.boostX3),
          "shareholderVote": String(skills.shareholderVote),
          "shareholderVoteTONInvestment": String(tonInvestment > 0 ? tonInvestment : skills.shareholderVoteTONInvestment),
        },
      };

      try {
        
        if (consoleAllowed) console.log("[CONTEXT.sendUserDataToServer] jsonObj:", JSON.stringify(jsonObj));

        const requestUrl = `${CONSTANTS.server}${CONSTANTS.apiLinkUpdateUserRecords}`;

        if (consoleAllowed) console.log("[CONTEXT] requestUrl:", requestUrl);

        await fetch(requestUrl, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(jsonObj),
        });
        
      } catch (error) {
        console.error("[CONTEXT.sendUserDataToServer] error:", error);
      }
    } else {
      console.log("[CONTEXT.sendUserDataToServer] Telegram user unknown");
    }
  }
  

  // Как только произойдет обновление какой-либо переменной из этого списка -
  // обновляем данные на сервере
  useEffect(() => {
    sendUserDataToServer();
  }, [
    dailyBoosts.fullTank, 
    dailyBoosts.tapMaster, 
    skills.activeTapIncrement,
    skills.boostX2,
    skills.boostX3,
    skills.energyConsumption,
    skills.energyLimit,
    skills.energyRestoration,
    skills.energyConsumption,
    skills.scoreIncrement,
    skills.shareholderVote,
    skills.tapBot,
    skills.tapIncrement,
    skills.shareholderVote,
    skills.shareholderVoteTONInvestment,
    bonus.big,
    bonus.medium,
    bonus.small,
  ]);

  return (
    <UserContext.Provider value={{
      user,
      bonus,
      skills,
      dailyBoosts,
      friendsArray,
      sunraysVisible,
      tonInvestment,
      setUser,
      setBonus,
      setSkills,
      setDailyBoosts,
      setFriendsArray,
      setSunraysVisible,
      setTonInvestment,
      wallet: null,
      userFriendlyAddress: null,
      initiateTonPayment,
      sendUserDataToServer
    }}>
      {children}
    </UserContext.Provider>
  );
};

export function useUser() {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
}
