/*================================================ 
    Страница загрузки приложения
================================================*/

import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import useTelegramUserData from '../components/useTelegramUserData';
import { useUser, FriendsState } from '../contexts/context';
import CONSTANTS from '../components/constants';
import { BootScreen } from './bootScreen';
import WebApp from '@twa-dev/sdk';

interface FriendData {
    tgId: number,
    tgUsername: string,
    tgFirstName: string,
    tgLastName: string,
    tgPhotoUrl: string,
    tgIsBot: boolean
};

interface UserData {
    user: {
        locale: string;
        telegramUserID: number;
        telegramUsername: string;
        telegramFirstName: string;
        telegramLastName: string;
        telegramIsPremium: boolean;
        telegramPhotoUrl: string;
        telegramIsBot: boolean;
        wallet: string;
        score: number;
        taps: number;
        botMined: number;
        lastSeenActivity: number;
        friends: number;
    },
    bonus: {
        big: number;
        medium: number;
        small: number;
    },
    dailyBoosts: {
        tapMaster: number;
        fullTank: number;
        adsShown: number;
        energy: number;
    },
    skills: {
        tapIncrement: number;
        scoreIncrement: number;
        energyLimit: number;
        energyRestoration: number;
        energyConsumption: number;
        tapBot: boolean;
        boostX2: boolean;
        boostX3: boolean;
        shareholderVote: boolean;
        shareholderVoteTONInvestment: number;
    },
    friendsArray: FriendData[];
}

export function BootPage() {

    const consoleAllowed = true;
    const alertAllowed = false;

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const {user, bonus, dailyBoosts, skills, setUser, setBonus, setDailyBoosts, setSkills, setFriendsArray, /*updateUser, updateBonus, updateDailyBoosts, updateSkills, updateFriendsArray*/ } = useUser();
    const [retryCount, setRetryCount] = useState<number>(0);
    const MAX_RETRIES = 2;

    const { telegramUserInfo } = useTelegramUserData();

    // Получение идентификатора приглашающего (передается как параметр "startapp")
    // Ссылка работает только, если параметр передан вот так: ?startapp=...
    // Все другие варианты (типа ?start=...) не работают
    const startParam = WebApp.initDataUnsafe.start_param || '';
    let referrerId = 0;
    if (startParam.startsWith('r_')) {
        referrerId = Number(startParam.slice(2)); // отрезает "r_" и оставляет остальное
        //console.log(referrerId); // будет "428856366"
    }
    
    if (alertAllowed) alert("[BOOT] referrerId: \n" + referrerId);

    // Обработчик массива друзей
    const processFriendsArray = (friendsArray: FriendData[]): FriendsState[] => {
        //if (consoleAllowed) console.log("[processFriendsArray] Input array:", friendsArray);
        
        if (!Array.isArray(friendsArray)) {
            if (consoleAllowed) console.error("[processFriendsArray] Input is not an array");
            return [];
        }
      
        const processedFriends = friendsArray.map(friend => {
            //if (consoleAllowed) console.log("[processFriendsArray] Processing friend:", friend);
          
            // Проверяем наличие всех необходимых полей
            if (!friend || typeof friend !== 'object') {
                if (consoleAllowed) console.warn("[processFriendsArray] Invalid friend object:", friend);
                return null;
            }
      
            // Проверяем точные имена полей
            return {
                tgId: friend.tgId,
                tgFirstName: friend.tgFirstName,
                tgLastName: friend.tgLastName,
                tgUsername: friend.tgUsername,
                tgPhotoUrl: friend.tgPhotoUrl,
                tgIsBot: friend.tgIsBot
            };
        }).filter(friend => friend !== null) as FriendsState[];
      
        //if (consoleAllowed) console.log("[processFriendsArray] Processed friends:", processedFriends);
        return processedFriends;
    };
    
    
    // Полуаем данные с сервера
    const fetchData = useCallback(async () => {
        if (!telegramUserInfo || !telegramUserInfo.id) {
            if (consoleAllowed) console.log('[BOOT.fetchData] No valid Telegram user info');
            return null;
        }

        if (alertAllowed) alert("[BOOT.fetchData] \n" + "telegramUserInfo.language_code: " + telegramUserInfo.language_code);

        try {
            const queryParams = new URLSearchParams({
                tg_user_id: String(telegramUserInfo.id),
                tg_username: encodeURIComponent(String(telegramUserInfo.username)),
                tg_first_name: encodeURIComponent(String(telegramUserInfo.first_name)),
                tg_last_name: encodeURIComponent(String(telegramUserInfo.last_name)),
                tg_locale: encodeURIComponent(String(telegramUserInfo.language_code)),
                tg_is_premium: String(telegramUserInfo.is_premium),
                tg_is_bot: String(telegramUserInfo.is_bot),
                tg_photo_url: String(telegramUserInfo.photo_url),
                tg_referrer_id: String(referrerId),
            });

            const requestUrl = `${CONSTANTS.server}${CONSTANTS.apiLinkGetUserRecords}?${queryParams.toString()}`;

            const response = await fetch(requestUrl, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch user data');
            }

            const data: UserData = await response.json();
            
            if (consoleAllowed) console.log("[BOOT.fetchData] Received data:", data);
            if (alertAllowed) alert("[BOOT.fetchData] Received data:" + JSON.stringify(data));

            // Обновляем контекст
            setUser((prev) => ({
                ...prev,
                locale: data.user.locale,
                telegramUserID: data.user.telegramUserID,
                telegramUsername: data.user.telegramUsername,
                telegramFirstName: data.user.telegramFirstName,
                telegramLastName: data.user.telegramLastName,
                telegramIsPremium: data.user.telegramIsPremium,
                telegramPhotoUrl: telegramUserInfo.photo_url,
                isBot: telegramUserInfo.is_bot,
                wallet: data.user.wallet,
                score: data.user.score,
                taps: data.user.taps,
                botMined: data.user.botMined,
                lastSeenActivity: data.user.lastSeenActivity,
                friends: data.user.friends,
            }))

            setBonus((prev) => ({
                ...prev,
                big: data.bonus.big,
                medium: data.bonus.medium,
                small: data.bonus.small,
            }))

            setDailyBoosts((prev) => ({
                ...prev,
                tapMaster: data.dailyBoosts.tapMaster,
                fullTank: data.dailyBoosts.fullTank,
                adsShown: data.dailyBoosts.adsShown,
                energy: data.dailyBoosts.energy,
            }))

            setSkills((prev) => ({
                ...prev,
                tapIncrement: data.skills.tapIncrement,
                activeTapIncrement: data.skills.tapIncrement,
                scoreIncrement: data.skills.scoreIncrement,
                energyLimit: data.skills.energyLimit,
                energyRestoration: data.skills.energyRestoration,
                energyConsumption: data.skills.energyConsumption,
                tapBot: data.skills.tapBot,
                boostX2: data.skills.boostX2,
                boostX3: data.skills.boostX3,
                shareholderVote: data.skills.shareholderVote,
                shareholderVoteTONInvestment: data.skills.shareholderVoteTONInvestment,
            }));

            const friendsArray = processFriendsArray(data.friendsArray ?? []);

            if (consoleAllowed) console.log("[BOOT.fetchData] data.friendsArray:", data.friendsArray);
            if (consoleAllowed) console.log("[BOOT.fetchData] friendsArray:", friendsArray);
            
            // setFriendsArray((prev) => ({
            //     ...prev,
            //     friendsArray
            // }));

            // Так правильно, без prev
            setFriendsArray(friendsArray);

            return data;
        } catch (error) {
            if (consoleAllowed) console.error('[BOOT.fetchData] Error loading user data:', error);
            return null;
        }
    //}, [updateUser, updateBonus, updateDailyBoosts, updateSkills, updateFriendsArray, telegramUserInfo]);
    }, [setUser, setBonus, setDailyBoosts, setSkills, setFriendsArray, telegramUserInfo]);


    // Загружаем данные с сервера при рендеринге страницы
    useEffect(() => {
        if (!telegramUserInfo || !telegramUserInfo.id) {
            if (consoleAllowed) console.log("[BOOT.useEffect] Waiting for Telegram user info...");
            return;
        }

        const loadUserData = async () => {
            try {
                setIsLoading(true);
                const result = await fetchData();

                if (result) {
                    // Успешная загрузка данных
                    if (consoleAllowed) console.log("[BOOT.useEffect] Data loaded successfully");

                    // Пока снова не обратится к контексту - он будет старый
                    //alert("[BOOT.loadUserData] user: " + JSON.stringify(user));
                    //console.log("[BOOT.loadUserData] user:", JSON.stringify(user));
                    // Пауза для обеспечения обновления контекста
                    //await new Promise((resolve) => setTimeout(resolve, 3000));
                    // Только после этого значения обновляются

                    //navigate('/tap'); // Перенаправление после загрузки
                    
                } else if (retryCount < MAX_RETRIES) {
                    if (consoleAllowed) console.log(`[BOOT.useEffect] Retry attempt ${retryCount + 1}`);
                    setRetryCount((prev) => prev + 1);
                }
            } catch (error) {
                if (consoleAllowed) console.error('[BOOT.useEffect] Error in loadUserData:', error);
            } finally {
                setIsLoading(false);
            }
        };

        loadUserData();
    }, [telegramUserInfo, fetchData, retryCount, navigate]);

    // Переходим на следующую страницу только после обновления контекста
    useEffect(() => {
        if (user.telegramUserID > 0 && user.score > 1) {
            if (consoleAllowed) console.log("[BOOT.useEffect] user.telegramUserID:", user.telegramUserID);
            if (consoleAllowed) console.log("[BOOT.useEffect] user.score:", user.score);
            if (consoleAllowed) console.log("[BOOT.useEffect] Контекст обновился. Переходим на другую страницу");
            navigate('/tap'); // Перенаправление после загрузки
        }
    }, [user, bonus, dailyBoosts, skills, navigate]);

    return <BootScreen />;
}